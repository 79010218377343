import { PrismicRichText } from "@prismicio/react";
import clsx from "clsx";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { linkResolver } from "../../utils/linkResolver";
import { buttonCls } from "../Button";
import { Heading2 } from "../Heading2";
import Section from "../Section";
import { Video } from "../Video";

export const TallyEmbeddedForm = ({ data: { primary } }) => {
  const {
    section_title,
    subline,
    main_content,
    tally_form_id,
    popup,
    popup_button_text,
    slice_theme,
  } = primary;

  useEffect(() => {
    const widgetScriptSrc = "https://tally.so/widgets/embed.js";

    const load = () => {
      if (popup) return;

      if (typeof Tally !== "undefined") {
        Tally.loadEmbeds();
      } else {
        // Fallback: set the iframe src manually.
        document
          .querySelectorAll("iframe[data-tally-src]:not([src])")
          .forEach(iframeEl => {
            iframeEl.src = iframeEl.dataset.tallySrc || "";
          });
      }
    };

    if (typeof Tally !== "undefined") {
      load();
    } else if (
      document.querySelector(`script[src="${widgetScriptSrc}"]`) === null
    ) {
      const script = document.createElement("script");
      script.src = widgetScriptSrc;
      script.onload = load;
      script.onerror = load;
      document.body.appendChild(script);
    } else {
      load();
    }

    // Additionally, force a re-check after a short delay.
    const timer = setTimeout(() => {
      if (typeof Tally !== "undefined") {
        Tally.loadEmbeds();
      }
    }, 500);

    return () => clearTimeout(timer);
  }, [popup]);

  return (
    <Section className={slice_theme} paddingY="py-8 md:py-20 lg:py-24">
      <Helmet>
        <script src="https://tally.so/widgets/embed.js" async defer />
      </Helmet>
      <div className={clsx("block md:gap-8", !popup && "md:flex")}>
        <div
          className={clsx(
            "md:flex-1",
            popup ? "text-center" : "md:max-w-1/2 md:w-1/2"
          )}
        >
          <PrismicRichText
            field={section_title.richText}
            linkResolver={linkResolver}
            components={{
              heading2: ({ children }) => (
                <Heading2
                  className={clsx(popup && "ml-0", popup && "max-w-none")}
                >
                  {children}
                </Heading2>
              ),
            }}
          />
          <p className="text-xl md:text-2xl font-display my-5">{subline}</p>
          <PrismicRichText
            field={main_content.richText}
            linkResolver={linkResolver}
            components={{
              embed: ({ node }) =>
                node.oembed.type === "video" ? (
                  <Video videoLink={node.oembed.embed_url} />
                ) : null,
              hyperlink: ({ children, node }) => (
                <a
                  className="text-secondary"
                  href={linkResolver(node.data)}
                  target={
                    node.data.link_type === "Document" ? "_self" : "_blank"
                  }
                >
                  {children}
                </a>
              ),
            }}
          />
          {popup && (
            <div className="mt-8 flex justify-center">
              <button
                className={buttonCls}
                data-tally-open={tally_form_id}
                data-tally-emoji-text="👋"
                data-tally-emoji-animation="wave"
                data-tally-layout="modal"
                data-tally-width="640"
              >
                {popup_button_text !== null ? popup_button_text : "Open Form"}
              </button>
            </div>
          )}
        </div>
        {!popup && (
          <div className="md:flex-1 md:max-w-1/2 md:w-1/2 mt-12 md:mt-0">
            <iframe
              data-tally-src={`https://tally.so/embed/${tally_form_id}?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1`}
              loading="lazy"
              width="100%"
              height="216"
              title="Newsletter subscribers"
            ></iframe>
          </div>
        )}
      </div>
    </Section>
  );
};
