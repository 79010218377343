import PropTypes from "prop-types";
import React from "react";

const Address = ({ colGap = "lg:gap-x-10" }) => (
  <>
    <div>
      <h2 className="font-body uppercase leading-none mb-5">Visit us</h2>
      <a
        href="https://www.google.com/maps/dir//Stoked,+Mejlgade+80,+5.+sal,+8000+Aarhus+C,+Denmark/@56.161502,10.2143252,15z/data=!4m16!1m6!3m5!1s0x0:0x22ff1d810a5bf500!2sStoked!8m2!3d56.161502!4d10.2143252!4m8!1m0!1m5!1m1!1s0x464c3f97db9b6ea7:0x22ff1d810a5bf500!2m2!1d10.2143252!2d56.161502!3e2"
        className="underline cursor-pointer hover:opacity-75 duration-300 ease-in-out"
        target="_blank"
      >
        Nørre Alle 70A
        <br />
        Aarhus, 8000 Denmark
      </a>
    </div>

    <div>
      <h2 className="font-body uppercase leading-none mb-5">Get in touch</h2>
      <a
        href="mailto:hello@thisisstoked.com"
        className="underline cursor-pointer hover:opacity-75 duration-300 ease-in-out"
      >
        hello@thisisstoked.com
      </a>
      <a href="tel:+4525398438">
        <p>+45 25 39 84 38</p>
      </a>
    </div>
  </>
);

Address.propTypes = {
  paddingY: PropTypes.string,
};

export default Address;
